<template>
	<div>
		<myheader></myheader>
		<div id="details">
			<div class="bg"></div>
			<div class="details">
				<!--  -->
				<div class="title">
					<p>团队定制</p>
					<img src="../assets/other/right.png" alt="">
					<p>经典案例详情</p>
				</div>
				<!--  -->
				<div class="detail">
					<div class="dleft">

						<el-carousel height="320px">
						    <el-carousel-item v-for="(item,i) in view.attachment" :key="i">
								<img :src="item" class="pic">
						    </el-carousel-item>
						</el-carousel>
						

					</div>
					<div class="dright">
						<div class="info">
							<h3>{{view.goods_name}} </h3>
							

							<div class="say">
								{{view.activities_content}}
							</div>
							<div class="dz" @click="dialogVisible=true">
								马上定制
							</div>
						</div>
					
					</div>
				</div>
			</div>

			

			
			<div class="roll m2" v-if="view.goods_case_trip.length>0">
				<h2>
					活动行程<span>以下行程时间均为当地时间</span>
				</h2>
				<div class="timer">
					<div class="tleft">
						<div class="daylist flex-between">
							<img src="../assets/other/pleft.png" alt="">

							<ul>
								<li v-for="(item,i) in view.goods_case_trip" :class="{'active':i==day}" :key="i" @click="chose(i)">
									第<span>{{i+1}}</span>日
								</li>

							</ul>
							<img src="../assets/other/pright.png" alt="">
						</div>
						<div class="banner">
							<el-carousel height="340px">
							    <el-carousel-item v-for="(it,i) in activeobj.photo" :key="i">
									<img :src="it" class="pic">
							    </el-carousel-item>
							</el-carousel>
						</div>

					</div>
					<div class="tright">
						<div class="adresslist">
							<p>Day{{day+1}}</p>
							<p>
								{{activeobj.name}}
							</p>
						</div>
						<div class="timeline">
						  <el-timeline>
						    
						    <el-timeline-item :timestamp="it.trip_time" placement="top"
							 :icon='icons[it.type]' size='large'
							 v-for="(it,i) in activeobj.goods_case_trip_time" :key="i">
						      <el-card>
						        <h4>{{it.type | triptype}} </h4>
						        <p>{{it.content}}</p>
								<p style="color:#999">{{it.note}}</p>
						      </el-card>
						    </el-timeline-item>
						    
						  </el-timeline>
						</div>
					</div>

				</div>
				<div class="boxtext" style="margin-top:20px">
					<h3>行程备注：</h3>
					<div>
						{{notes}}
					</div>
				</div>
			</div>

			
			
		</div>

		<el-dialog title="我要定制"  :visible.sync="dialogVisible"
		  width="800px">
			<div class="dialogmsg">
				<div class="formmsg">
				<table>
					<tr>
						<td>出发地</td>
						<td><input type="text" placeholder="请输入出发地" v-model="form.origin" class="input"></td>
						<td>目的地</td>
						<td><input type="text" placeholder="请输入目的地"  v-model="form.destination" class="input"></td>
					</tr>
					<tr>
						<td>预计出游日期</td>
						<td>
							<el-date-picker
							v-model="form.travel_date"
							type="date"
							placeholder="选择日期"
							format="yyyy 年 MM 月 dd 日"
      						value-format="yyyy-MM-dd">
							</el-date-picker>
						</td>
						<td>游玩天数</td>
						<td><input type="text" placeholder="请输入游玩天数"  v-model="form.days" class="input"></td>
					</tr>
					<tr>
						<td>出游人数</td>
						<td>
							<el-input-number size="mini" v-model="form.adults_number"></el-input-number>
							<span class="a1">成人</span>
						</td>
						
						<td  class="a2">
							<el-input-number size="mini" v-model="form.children_number"></el-input-number>
							
						</td>
						<td class="a3">儿童 <span >(12周岁(不含)以内)</span></td>
					</tr>
					<tr>
						<td>联系人</td>
						<td><input type="text" placeholder="请输入联系人" v-model="form.contact" class="input"></td>
						<td>手机号码</td>
						<td><input type="text" placeholder="请输入手机号码" v-model="form.phone" class="input"></td>
					</tr>
					<tr>
						<td>微信号</td>
						<td><input type="text" placeholder="请输入微信号" v-model="form.wechat_number" class="input"></td>
						
					</tr>
					<tr>
						<td></td>
						<td>
							<el-checkbox v-model="iswechat" @change="changes">同手机号</el-checkbox>
						</td>
						
					</tr>
				</table>
				<div class="but" @click="adddz">提交需求</div>
			</div> 
			</div>

		</el-dialog>
		<myfooter></myfooter>
		<myright></myright>
	</div>
</template>

<script>
import myheader from '../components/header.vue'
import myfooter from '../components/footer.vue'
import myright from '../components/right.vue'
import {caseInfoAPI,teamAddAPI} from "@/api/team"
import {travelnotesAPI} from "@/api/details"
export default {
  components: {
    myheader, myfooter, myright
  },
  data () {
    return {
		dialogVisible:false,
        view:{},
		casetriplist:[],
		day:0,
		form:{
		  origin:"",
		  destination:"",
		  travel_date:"",
		  days:"",
		  adults_number:1,
		  children_number:0,
		  contact:"",
		  phone:"",
		  wechat_number:""
	  },
	  iswechat:false,
	  order_sn:"000",
	   icons:["el-wu-icon6","el-wu-icon1","el-wu-icon2","el-wu-icon3","el-wu-icon4","el-wu-icon5","el-wu-icon6"],
	  notes:""

    }
  },
  created () {
    const id = this.$route.query.id
    this.id=id
	this.getinfo(id)
  },
  filters:{
	  type(i){
		//   1=城市微游；2=境外旅行；3=国内长途；4=周边活动；5=趣·户外；6=趣·旅游
		  var arr=["","城市微游","境外旅行","国内长途","周边活动","趣·户外","趣·旅游"]
		  return arr[i]
	  },
	  triptype(i){
		//   1  2   4  5 
		var arr=["","交通","景点","餐饮","住宿","其它"]
		  return arr[i]
	  }
  },
  computed:{
	  
	 totalprice(){
		var a=this.adult*this.nowprice.adult_price
		var c=this.child*this.nowprice.children_price
		return a+c
	 },
	 activeobj(){
		 let arr=[]
		 arr=this.view.goods_case_trip==[] ? [{photo:[],name:'', goods_case_trip_time:[]}] : this.view.goods_case_trip

		 if (arr.length>0) {
			  var list=this.view.goods_case_trip

		 		return list[this.day]
		 } else {
			 return {
				 photo:[],
				 name:'',
				 goods_case_trip_time:[]
			 }
		 }
		
	 }
  },
 
  methods: {
	  adddz(){
		  teamAddAPI(this.form).then(res=>{
			  this.order_sn=res.order_sn
			  this.dialogVisible=true
		  })
	  },
	  changes(e){
		  if(e){
			  this.form.wechat_number=this.form.phone
		  }
	  },

	getinfo(){
		caseInfoAPI({id:this.id}).then(res=>{
			this.view=res.cateView
			this.casetriplist=res.cateView.goods_case_trip
		})
		travelnotesAPI().then(res=>{
			this.notes=res.cateView.note
		})
	},
	chose(i){
		this.day=i
	}

  }
}
</script>

<style lang="scss">
@import url('../components/icon.css') ;
.dialogmsg{
		.formmsg{
		width:630px;

		margin: 0px auto;
		// background-color: red;
		tr{
			height:45px;
			td{
				text-align: right;
				min-width:100px;
				font-weight: 400;

				color: #333333;
				line-height: 22px;
				.input{
					margin-left:20px;
					height:35px;
					width:190px ;
					background: #f8f8f8;
					border-radius: 4px;
					text-indent: 1em;
				}
				.el-date-editor.el-input, .el-date-editor.el-input__inner {
					width: 190px;
				}
				.a1{
					margin-right: 25px;
					margin-left: 5px;
				}
				
			}
			.a3{
				text-align: left;
				margin-left: 10px;
				span{
					font-size: 12px;
					color: #666;
				}
			}
		}
		.but{
			width: 240px;
			height: 45px;
			margin: 30px auto;
			background: #ff7c00;
			border-radius: 8px;
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			color: #ffffff;
			line-height: 45px;

		}
	}
}
#details{

	ul{
		.el-carousel__button{

			height:6px;
			border-radius: 4px;
			opacity:1;
			width:6px;
			background-color: #fff;
		}
		.is-active .el-carousel__button{
			background-color: #ff7c00;
			width:30px;
		}
	}

.bg{
	height: 10px;
	min-width: 1200px;
	width: 100%;
	background: linear-gradient(#ececec,#fff);
}
.details{
	width: 1200px;
	margin: 0px auto;
}
.title{
	display: flex;
	align-items: center;
	margin: 10px 0 15px;
	font-size: 15px;
	p{
		color: #666666;
	}

	line-height: 21px;
	img{
		width: 10px;
		height: 11px;
		margin: 0 10px;
	}
}
.detail{
	display: flex;
	.dleft{
		width: 490px;
		.pic{
			width: 100%;
			min-height: 320px;
		}
		.calendar{
			margin-top: 20px;
			background: #f7f7f7;
			border: 1px solid #e6e6e6;
			border-radius: 6px;
			width: 490px;
			height: 230px;
			.daylist{
				border-bottom: 1px solid #e6e6e6;
				margin-top: 10px;
				img{
					width: 21px;
					margin:0 5px;
				}
				ul{
					flex: 1;
					display: flex;
					li{
						position: relative;
						font-weight: 500;
						font-size: 16px;
						width: 60px;
						text-align: center;
						color: #333;
						line-height: 36px;
						height: 36px;
						margin:0 5px;
						cursor: pointer;
						.bottom{
							position: absolute;
							bottom: -2px;
							left:-5px;
							width: 60px;
						}
					}
					.active{
						color:  #ff7c00;
					}
				}
			}
			.callist{
				display: flex;
				margin-top: 15px;
				overflow: hidden;
				.calitem{
					margin-left: 5px;
					width: 116px;
					height: 150px;
					background: #ffffff;
					border: 1px solid #e6e6e6;
					border-radius: 2px;
					padding: 0;
					.c1{
						border-radius: 2px 2px 0 0;
						margin: 0;
						width: 100%;
						height: 33px;
						background: #bac5d2;
						font-size: 14px;
						text-align: center;
						color: #ffffff;
						line-height: 33px;
					}
					p{
						font-size: 14px;
						text-align: center;
						color: #333333;
						line-height: 26px;
						margin-top:5px;
					}
					.c4{
						width: 87px;
						height: 25px;
						background: #fff;
						border-radius: 2px;
						margin: 15px auto 0;
					}

				}
				.active{
					background-color: #FFECCB;
					border: 1px solid #ff7c00;
					.c1{
						background: #ff7c00;
					}
					.c4{
						color:#FF4C12 ;
					}
				}
			}

		}

	}
	.dright{
		flex: 1;
		margin-left: 20px;
		height: 500px;

		.info{
			height: 320px;
			position: relative;
			h3{
				font-size: 22px;
				font-weight: 600;
				line-height: 34px;

			}
			.label{
				ul{
					display: flex;
					margin-top: 15px;
					li{
						border: 2px solid #ff7c00;
						border-radius: 5px;
						font-size: 15px;
						padding: 0 10px;
						color: #ff7c00;
						line-height: 28px;
						margin-right:15px;
						font-weight: 500;
					}
				}
			}
			.star{
				margin: 4px 0;
				display: flex;
				p{
					font-size: 14px;
					line-height: 50px;
					display: flex;
					margin-right: 40px;
					align-items: center;
					>span{
						margin-right: 15px;
					}

				}
			}
			.adress{
				p{
					display: flex;
					align-items: center;
					margin-right: 20px;
					img{
						width: 19px;
						height: 19px;
					}
					span{
						font-size: 16px;
						font-weight: 600;
						color: #ff7c00;
						line-height: 22px;
						margin: 0 3px;
					}
					.t{
						margin-left: 6px;
						color: #333333;
					}
				}

			}
			.say{
				margin-top: 10px;
				min-height: 180px;
				p{
					font-size: 14px;
					color: #333333;
					line-height: 30px;
					font-weight: 500;
				}
				
			}
			.dz{
				width: 321px;
				height: 45px;
				background: #ff7c00;
				border-radius: 4px;
				font-size: 18px;
				font-weight: 500;
				text-align: center;
				color: #ffffff;
				line-height: 45px;
			}
		}

		

	}
}
.signup{
	position: relative;
	width: 1200px;
	margin: 20px auto;
	height: 90px;
	background: #f7f7f7;
	border-radius: 6px;
	display: flex;
	align-items: center;
	padding:10px 15px;
	.sign{
		width: 150px;
		p{
			font-size: 16px;

			color: #333333;
			line-height: 30px;
			span{
				color: #FF4C12;
				margin: 0 3px;
			}
		}
	}
	.imgs{
		flex: 1;
		display: flex;
		.img{
			width: 50px;
			margin: 0 13px;
			.tou{
				position: relative;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				overflow: hidden;
				img{
					width: 50px;
					height: 50px;
				}
				.bgtext{
					position: absolute;
					left: 0;
					bottom: 0;
					width:50px;
					height: 18px;
					background: rgba(0,0,0,0.70);
					color: #fff;
					text-align: center;
					font-size: 12px;
					line-height: 18px;
				}

			}
			p{
				text-align: center;
				font-size: 12px;
				margin-top: 5px;
				color: #666666;
				line-height: 17px;
			}
		}
	}
	.more{
		position: absolute;
		right: 10px;
		top: 2px;
		font-size: 14px;
		margin-top: 5px;
		color: #666666;
		line-height: 17px;
		cursor: pointer;
	}
}
.menus{
	width: 1200px;
	margin: 20px auto;
	border-bottom: 2px solid #cccccc;
	ul{
		display: flex;

		li{
			font-size: 18px;
			margin: 10px 45px 10px 0;
			color: #666666;
			line-height: 25px;
			cursor: pointer;

		}
		.active{
			color: #FF7C00;
		}
	}
}
.roll{
	width: 1200px;
	margin: 20px auto;

	h2{
		font-size: 24px;
		font-weight: 500;
		color: #333333;
		line-height: 33px;
		margin: 10px 0;
	}
}
.boxtext{
	background: #f9f9f9;
	border-radius: 10px;
	padding: 15px 20px 5px 0;
	line-height: 25px;
	h3{
		border-left: 3px solid #FF7C00;
		padding-left: 17px;
		margin-bottom: 10px;
	}
	div{
		padding-left: 20px;
		padding-bottom: 10px;
		p{
			color: #666666;
			color: 16px;
			line-height: 30px;
			font-size: 16px;
		}

	}
}
.m1{
	img{
		width: 100%;
		margin-bottom: 10px;
		border-radius: 3px;
	}
}
.m2{
	h2{
		span{
			font-size: 16px;
			font-weight: 400;
			color: #666666;
			line-height: 33px;
			margin-left: 25px;
		}
	}
	.timer{
		display: flex;
		.tleft{
			width: 555px;
			.daylist{
				width: 555px;
				img{
					width: 21px;
				}
				ul{
					flex: 1;
					display: flex;
					li{
						border: 1px solid #e6e6e6;
						border-radius: 2px;
						font-size: 14px;
						padding: 0 9px;
						color: #666666;
						line-height: 32px;
						height: 32px;
						margin:0 5px;
						cursor: pointer;
						span{
							color: #666666;
							font-size: 18px;
							margin: 0 2px;
							line-height: 32px;
						}
					}
					.active{
						background-color: #ff7c00;
						border: 1px solid #ff7c00;
						color: #fff;
						span{
							color: #fff;
						}
					}
				}
			}

			.banner{
				margin-top: 30px;
				img{
					width: 100%;
					border-radius: 5px;
					min-height: 100%;
				}
			}
		}
		.tright{
			flex: 1;

			margin-left: 30px;
			.adresslist{
				display: flex;
				p:nth-child(1){
					font-size: 18px;
					margin-right: 15px;
					color: #FF7C00;
					line-height: 25px;
				}
				p:nth-child(2){
					font-size: 18px;
					margin-right: 15px;
					color: #333;
					line-height: 25px;
					span{
						color: #333;
					}
				}
			}
			.timeline{
				margin-top: 30px;
				margin-bottom: 10px;
			}
		}
	}

}
.m5{
	.comment{
		display: flex;
		margin-top: 15px;
		.tou{
			position: relative;
			width: 50px;
			height: 50px;
			.t{
				width: 50px;
				height: 50px;
				border-radius: 50%;
			}
			.v{
				position: absolute;
				left: 0;
				bottom:-5px;
				width:50px;
			}
		}
		.text{
			margin-left: 20px;
			.user{
				p:nth-child(1){
					font-size: 16px;
					font-weight: 500;
					color: #333333;
					line-height: 30px;
				}
				p:nth-child(2){
					font-size: 13px;

					color: #666;
					line-height: 20px;
				}

			}
			.usermsg{
				margin-top: 15px;
				line-height: 30px;
				ul{
					display: flex;
					li{
						margin-right: 20px;
						max-width: 160px;
						height: 100px;
						overflow: hidden;
						img{
							height: 100px;
						}
					}
				}
			}
			.qumsg{
				margin-top: 15px;
				line-height: 30px;
				padding:10px 20px;
				background: #f9f9f9;
				span{
					color: #FF7C00;
				}
			}
		}

	}
}
.m6{
	// width: 1200px;
	// height: 150px;
	position: relative;
	textarea{
		width: 1200px;
		height: 150px;
		background: #ffffff;
		border: 2px solid #cccccc;
		border-radius: 6px;
		line-height: 30px;
		text-indent: 10px;
		padding: 5 15px;
		font-size: 16px;
		color: #666;
	}
	button{
		position: absolute;
		bottom:4px;
		right: 0;
		width: 117px;
		height: 45px;
		background: #ff7c00;
		border-radius: 6px 0px 0px 0px;
		border: none;
		font-size: 18px;
		color: #fff;
	}

}
#page{
	margin: 30px auto;
	width: 400px;

	.el-pager .active{
		background-color: #ff7c00 !important;
	}
	.el-pager li.active{
		color: #fff;
	}
	.number:hover{
		color: #ff7c00;
	}
}
// 本页结束
.el-input-number .is-disabled{
	margin-left: 0 !important;

}
}
</style>
